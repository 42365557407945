.container-main-mini {
  display: flex;
  justify-content: center;
  max-width: 100%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-bottom: solid 1px gray;
  box-shadow: 0px 10px 10px #888888;
}
.main {
  max-width: 100%;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  background: white;
}

.container-main-shadow {
  display: flex;
  justify-content: center;
  max-width: 100%;
  background: white;
}
.container-box-shadow {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: inline-block;
  width: 100%;
  margin: 0px 15px 30px;
  box-shadow: -1px 0px 10px 0px darkgrey;
}

.container-slide-shadow {
  background-color: #fff;
  border-radius: 20px;
  padding: 15px;
  display: inline-block;
  width: 100%;
  margin: 0px 0px 20px;
  box-shadow: -1px 1px 5px 0px darkgrey;
}

.container-history-shadow {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  width: 90%;
  margin: 5px 3px 10px 1px;
  box-shadow: -1px 0px 5px 0px darkgrey;
}

.wrapper-hour {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
}

.box-hour {
  background-color: #d6e9ff;
  border-radius: 5px;
  font-size: 0.8rem;
  text-align: center;
  padding: 7px 5px;
  font-weight: 500;
  color: #555;
}
.box-hour-active {
  font-weight: 700;
  background-color: #2d6194;
  color: white;
}

.selector-date-schedule {
  font-weight: 500;
}

.bg-arrow {
  font-weight: 700;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: inline-block;
  text-align: center;
  line-height: 1.3;
  cursor: pointer;
}
.bg-arrow:hover {
  background-color: #eaf4ff;
  color: #2d6194;
}

.modal-container-content {
  padding: 20px;
}

.modal-title-content {
  color: #2d6194;
  font-weight: 600;
}

.modal-hour-content {
  color: darkgrey;
  font-weight: 600;
}
