@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slideHistory .slick-slide {
  padding: 8px;
}

.slick-prev,
.slick-next {
  display: none !important;
}

@media screen and (max-width: 400px) {
  /* .container-slider{
        max-width: 200px;
    } */
}
