.menu-left {
  height: 50px;
  width: 50px;
  margin-right: 5px;
}
.wh-28px {
  width: 28px;
  height: 28px;
}

.font-size-20px {
  font-size: 15px;
}
.font-size-24px {
  font-size: 24px;
}

.font-size-30px {
  font-size: 30px;
}
